<template>
  <section
    v-if="Object.keys(singleInvoice).length > 0"
    class="invoice-preview-wrapper"
  >

    <b-row
      class="invoice-preview"
    >
      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-card
          ref="invoiceBody"
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">

            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

              <!-- Header: Left Content -->
              <div >
                <div class="logo-wrapper">
                  <h3 class="text-primary">
                    {{ singleInvoice.invoice_from.name }}
                  </h3>
                </div>
                <p class="card-text mb-25">
                  {{ singleInvoice.invoice_from.address.address_line }}
                </p>
                <p class="card-text mb-25">
                  {{ singleInvoice.invoice_from.address.address_city }} {{ singleInvoice.invoice_from.address.address_province }} {{ singleInvoice.invoice_from.address.address_postal_code }}
                </p>
                <p>
                  <router-link
                    class="card-text mb-0"
                    :to="'mailto:' + singleInvoice.invoice_from.email"
                  >
                    {{ singleInvoice.invoice_from.email }}
                  </router-link>
                </p>
                <p>
                  <router-link
                    class="card-text mb-0"
                    to="tel:7785806107"
                  >
                    {{ singleInvoice.invoice_from.phone }}
                  </router-link>
                </p>
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <h4 class="invoice-title">
                  Invoice
                  <span class="invoice-number">#{{ singleInvoice.general.invoice_number }}</span>
                </h4>

                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    Date Issued:
                  </p>
                  <p class="invoice-date">
                    {{ singleInvoice.general.date }}
                  </p>
                </div>
                <b-badge
                  class="mt-1 float-left"
                  :variant="getVarient(singleInvoice.general.status)"
                >
                  {{ singleInvoice.general.status }}
                </b-badge>
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Invoice Client & Payment Details -->
          <b-card-body
            class="invoice-padding pt-0"
          >
            <b-row class="invoice-spacing">

              <!-- Col: Invoice To -->
              <b-col
                cols="12"
                xl="6"
                class="p-0"
              >
                <h6 class="mb-2">
                  Invoice To:
                </h6>
                <h6 class="mb-25">
                  {{ singleInvoice.invoice_to.name }}
                </h6>
                <p class="card-text mb-25" v-if="singleInvoice.invoice_to.address">
                  {{ singleInvoice.invoice_to.address.address_line }} {{ singleInvoice.invoice_to.address.address_city }} {{ singleInvoice.invoice_to.address.address_province }} {{ singleInvoice.invoice_to.address.address_postal_code }}
                </p>
                <p class="card-text mb-25">
                  {{ singleInvoice.invoice_to.phone }}
                </p>
                <p class="card-text mb-0">
                  {{ singleInvoice.invoice_to.email }}
                </p>
              </b-col>

              <!-- Col: Payment Details -->
              <b-col
                xl="6"
                cols="12"
                class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
              >
                <div>
                  <h6 class="mb-2">
                    Payment Details:
                  </h6>
                  <table>
                    <tbody>
                      <tr>
                        <td class="pr-1">
                          Card Type:
                        </td>
                        <td><span class="font-weight-bold">{{ singleInvoice.payment_detail.card.card_type }}</span></td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          Credit Card:
                        </td>
                        <td><span class="font-weight-bold">{{ singleInvoice.payment_detail.card.card_number }}</span></td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          Expiry Date:
                        </td>
                        <td><span class="font-weight-bold">{{ singleInvoice.payment_detail.card.expired_date }}</span></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <b-table
            responsive
            :items="singleInvoice.items.data"
            :fields="['description', 'amount']"
          >
            <template #cell(amount)="data">
              ${{ formatPrice(data.value * 0.01) }}
            </template>
          </b-table>
          <div class="totalPriceContainer d-flex justify-content-end">
            <div class="invoice-total-wrapper ">
              <div class="invoice-total-item">
                <p class="invoice-total-title">
                  Subtotal:
                </p>
                <p class="invoice-total-amount">
                  ${{ formatPrice(singleInvoice.general.subtotal * 0.01) }}
                </p>
              </div>
              <div
                v-if="singleInvoice.general.discount && singleInvoice.general.total !== 0"
                class="invoice-total-item"
              >
                <p class="invoice-total-title">
                  Discount:
                </p>
                <p class="invoice-total-amount">
                  - ${{ formatPrice(singleInvoice.general.discount.coupon.amount_off * 0.01) }}
                </p>
              </div>
              <div class="invoice-total-item">
                <p class="invoice-total-title">
                  Tax:
                </p>
                <p class="invoice-total-amount">
                  ${{ formatPrice(singleInvoice.general.tax * 0.01) }}
                </p>
              </div>
              <hr class="my-50">
              <div class="invoice-total-item">
                <p class="invoice-total-title">
                  Total:
                </p>
                <p class="invoice-total-amount">
                  ${{ formatPrice(singleInvoice.general.total * 0.01) }}
                </p>
              </div>
            </div>

          </div>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            @click="download"
          >
            Download
          </b-button>

          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="primary"
            class="mb-75"
            block
            @click="printInvoice"
          >
            Print
          </b-button>
        </b-card>
      </b-col>

    </b-row>
    <pdf
      ref="pdfContainer"
      :package-items="packageItems"
      :client="client"
      :payment="payment"
      :price="price"
      :download="download"
      :invoice-detail="invoiceDetail"
      :single-invoice="singleInvoice"
    />
  </section>
</template>

<script>

import {
  BRow, BCol, BCard, BCardBody, VBToggle, BBadge, BTable, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import pdf from './components/pdf.vue'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTable,
    BBadge,
    BButton,
    pdf,
  },
  data() {
    return {
      singleInvoice: {},
      packageItems: [{
        item: 'Standard Package',
        price: '$85',
      }],
      client: {
        name: 'Jae Choi',
        office: 'ONIKON Creative',
        phone: '604-111-1111',
        email: 'jae.choi@onikon.com',
      },
      payment: {
        type: 'Visa',
        card: '1234',
        expiry: '06/21',
      },
      price: {
        subTotal: 85,
        discount: 25,
        tax: 0.05,
      },
      invoiceDetail: {
        date: '19 Apr 2019',
        status: 'Paid',
        id: '5036',
      },
    }
  },
  created() {
    const { id } = this.$route.params

    this.$store.dispatch('billings/getSingleBilling', id).then(response => {
      if (response.code === 200) {
        console.log(response)
        this.singleInvoice = response.data
      }
    }).catch(err => {
      console.error(err)
    })
  },
  methods: {
    printInvoice() {
      window.print()
    },
    download() {
      this.$refs.pdfContainer.$refs.html2Pdf.generatePdf()
    },
    formatPrice(price) {
      const converted = (Math.round(price * 100) / 100).toFixed(2)
      if (price !== null) {
        return converted.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
      }

      return null
    },
    getVarient(status) {
      switch (status) {
        case 'Paid': return 'success'
        case 'Unpaid': return 'danger'
        default: return 'dark'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {

  // Global Styles
  .content-header-left{
    display:none;
  }
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
              > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
.totalPriceContainer{
    padding:2rem;
}
</style>
